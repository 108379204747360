<template>
  <div id="page-content-wrapper">
    <div
      class="bg-light page-header-wrapper border-bottom"
      style="height: 60px; padding: 0.875rem 1.25rem; font-size: 1.2rem;"
    >
      <div style="float: left;">Administration - Users</div>
    </div>

    <div id="page-content" class="text-left">
      <p class="text-left">
        These Users have access to the Portal and WxCC Dashboard.
        <br />If a User has the "atea_cloud_admin" role, they can access the admin
        portal to add customers.<br/>
        Note: a user can <strong>ONLY</strong> have one customer assigned.
      </p>
      <b-card-group deck>
        <b-card
          header="Users"
          style="max-width: 78rem;"
          class="mb-2"
          ref="formContainer5"
        >
          <b-card-body>
          <b-row>
            <b-col lg="6" class="my-1">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col lg="6" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalUserCount"
                :per-page="perPage"
                aria-controls="auth-users"
                size="sm"
              ></b-pagination>
            </b-col>
            </b-row>
            <b-table
              id="auth-users"
              striped
              hover
              bordered
              small
              :items="allUsers"
              :fields="fields"
              class="cy-table1 text-left"
              :per-page="perPage"
              :current-page="currentPage"
              :filter="filter"
              @filtered="onFiltered"
              :filter-included-fields="filterOn"
            >
              <template #cell(name)="data">
                <b-avatar variant="info" :src="data.item.picture"></b-avatar>
                <span
                  v-b-popover.hover="
                    `Last Login: ${data.item.last_login}\n Login Count: ${
                      data.item.logins_count
                    }`
                  "
                  title="User Login"
                  > {{ data.item.name }} <br/>
                {{ data.item.email }}</span>
              </template>
              <template #cell(email_verified)="data">
                <span v-if="!data.item.email_verified" style="background-color: orange;">No</span>
                <span v-if="data.item.email_verified">Yes</span>
              </template>
              <template #cell(customers)="data">
                <span v-for="(item, index) in data.item.customers" :key="index">
                  <font-awesome-icon
                    :title="'remove ' + item.customername"
                    @click="
                      removeCust(data.item, item.customerid, item.customername)
                    "
                    icon="minus-circle"
                    size="lg"
                    :style="{ color: 'red' }"
                  />
                  {{ item.customername }}
                  <br />
                </span>
              </template>
              <template #cell(roles)="data">
                <span v-for="(item, index) in data.item.roles" :key="index">
                  <font-awesome-icon
                    :title="'remove ' + item"
                    @click="removeRole(data.item, item)"
                    icon="minus-circle"
                    size="lg"
                    :style="{ color: 'red' }"
                  />
                  {{ item }}
                  <br />
                </span>
              </template>
              <template #cell(actions)="row">
                <b-button
                  size="sm"
                  @click="updateNameModal(row.item)"
                  class="mr-1"
                  >Edit Name</b-button
                >
                <b-button
                  size="sm"
                  :disabled="row.item.customers.length > 0"
                  @click="addCustomerModal(row.item)"
                  class="mr-1"
                  >Add Cust</b-button
                >
                <b-button size="sm" @click="addRoleModal(row.item)" class="mr-1"
                  >Add Role</b-button
                >
              </template>
            </b-table>
          </b-card-body>
          <em slot="footer">Users: {{ totalUserCount }}</em>
        </b-card>
      </b-card-group>
      <b-modal
        class="text-left"
        :id="infoModal.id"
        ref="modal"
        :title="infoModal.title"
        @hide="resetInfoModal"
      >
        <form ref="form">
          <b-form-group id="input-group-1" label="Name" label-for="input-1">
            <b-input
              id="input-1"
              v-model="infoModal.user.name"
              disabled
            ></b-input>
          </b-form-group>
          <b-form-group id="input-group-2" label="Email" label-for="input-2">
            <b-input
              id="input-2"
              v-model="infoModal.user.email"
              disabled
            ></b-input>
          </b-form-group>
          <span v-if="infoModal.customers.length < 1"
            >No more customers available to add</span
          >
          <span v-if="infoModal.customers.length > 0">
            Customers:
            <br />
            <span v-for="cus in infoModal.customers" :key="cus._id">
              <font-awesome-icon
                :title="'add ' + cus.customername"
                @click="
                  addCust(infoModal.user, cus.customerid, cus.customername)
                "
                icon="plus-circle"
                size="lg"
                :style="{ color: 'green' }"
              />
              {{ cus.customername }}
              <br />
            </span>
          </span>
        </form>
      </b-modal>
      <b-modal
        class="text-left"
        :id="infoModal2.id"
        ref="modal2"
        :title="infoModal2.title"
        @hide="resetInfoModal2"
      >
        <form ref="form2">
          <b-form-group id="input-group-1" label="Name" label-for="input-1">
            <b-input
              id="input-1"
              v-model="infoModal2.user.name"
              disabled
            ></b-input>
          </b-form-group>
          <b-form-group id="input-group-2" label="Email" label-for="input-2">
            <b-input
              id="input-2"
              v-model="infoModal2.user.email"
              disabled
            ></b-input>
          </b-form-group>
          <span v-if="infoModal2.roles.length < 1">
            No more roles available to add
          </span>
          <span v-if="infoModal2.roles.length > 0">
            Roles:
            <br />
            <span v-for="cus in infoModal2.roles" :key="cus._id">
              <font-awesome-icon
                :title="'add ' + cus.role"
                @click="addRoleToUser(infoModal2.user, cus)"
                icon="plus-circle"
                size="lg"
                :style="{ color: 'green' }"
              />
              {{ cus }}
              <br />
            </span>
          </span>
        </form>
      </b-modal>
      <b-modal
        class="text-left"
        :id="infoModal3.id"
        ref="modal3"
        :title="infoModal3.title"
        @hide="resetInfoModal3"
        @ok="saveUpdatedName"
      >
        <form ref="form3">
          <b-form-group id="input-group-1" label="Name" label-for="input-1">
            <b-input id="input-1" v-model="infoModal3.user.name"></b-input>
          </b-form-group>
          <b-form-group id="input-group-2" label="Email" label-for="input-2">
            <b-input
              id="input-2"
              v-model="infoModal3.user.email"
              disabled
            ></b-input>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import ReportService from "@/service/ReportService";
import moment from "moment-timezone";
export default {
  name: "UserAdmin",
  components: {},
  data: function() {
    return {
      allUsers: [],
      allRoles: ["atea_cloud_admin", "wxcc_dashboard_admin", "wxcc_dashboard_edit"],
      allCustomers: [],
      totalUserCount: 0,
      fields: ["name", "email_verified", "customers", "roles", "actions"],
      infoModal: {
        id: "info-modal",
        user: {},
        customers: []
      },
      infoModal2: {
        id: "info-modal2",
        user: {},
        roles: []
      },
      infoModal3: {
        id: "info-modal3",
        user: {}
      },
      perPage: 10,
      currentPage: 1,
      filter: null,
      filterOn: ["name", "email"],
    };
  },
  mounted() {
    this.getData();
  },
  computed: {},
  methods: {
    async getData() {
      let loader = this.$loading.show({
        loader: "bars",
        color: "#A12321",
        isFullPage: false,
        container: this.$refs.formContainer5
      });
      // let result = {
      //   data: [{
      //     "customerId": "23716f26-833a-4a7a-9020-00402cb7f4b1",
      //     "customerName": "Atea Systems Ltd"
      //   },{
      //     "customerId": "92b3299b-d9b7-41c7-89ec-7a63068b77bf",
      //     "customerName": "ACME LLC"
      //   }]
      // }
      ReportService.getAllCustomers(
        this.$auth.accessToken
      )
        .then(result => {
          this.allCustomers = result.data.sort((a, b) => {
            if (a.customername > b.customername) return 1
            else if (a.customername < b.customername) return -1
            else return 0
          });
          ReportService.getAllUsers(this.$auth.accessToken)
            .then(res => {
              this.totalUserCount = res.data.total;
              this.allUsers = res.data.users.map(user => {
                user.customers = result.data.reduce((list, cust) => {
                  if (
                    "user_metadata" in user && user["user_metadata"]["customerId"].indexOf(
                      cust.customerid
                    ) > -1
                  ) {
                    list.push(cust);
                  }
                  return list;
                }, []);
                user.roles = [];
                if (
                  "user_metadata" in user && 
                  "roles" in user.user_metadata &&
                  user.user_metadata.roles !== undefined &&
                  user.user_metadata.roles !== ""
                ) {
                  let role = user.user_metadata.roles.split(",");
                  user.roles = role;
                }
                if (user.last_login !== undefined) {
                  let lastLogin = new Date(user.last_login);
                  let d = moment(lastLogin);
                  user.last_login = d
                    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                    .format("DD-MM-YYYY HH:mm:ss z");
                }
                return user;
              });
            })
            .catch(error => {
              console.error(error);
            })
            .finally(() => {
              loader.hide();
            });
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
        })
        .finally(() => {
          // loader.hide();
        });
    },
    doToast(message, variant) {
      this.$bvToast.toast(message, {
        title: "Cloud Admin",
        autoHideDelay: 5000,
        variant: variant,
        appendToast: true,
        toaster: "b-toaster-top-center"
      });
    },
    addCustomerModal(item) {
      this.infoModal.title = `Add Customer to ${item.name}`;
      this.infoModal.user = item;
      this.infoModal.customers = this.allCustomers.filter(allCust => {
        if (
          "user_metadata" in item &&
          "customerId" in item["user_metadata"] &&
          item["user_metadata"]["customerId"]
            .split(",")
            .includes(allCust.customerId)
        )
          return false;
        return true;
      });
      this.$root.$emit("bv::show::modal", this.infoModal.id);
    },
    addRoleModal(item) {
      this.infoModal2.title = `Add Role to ${item.name}`;
      this.infoModal2.user = item;
      this.infoModal2.roles = this.allRoles.filter(allRole => {
        if (item.roles.includes(allRole)) return false;
        return true;
      });
      this.$root.$emit("bv::show::modal", this.infoModal2.id);
    },
    updateNameModal(item) {
      this.infoModal3.title = `Update Name for ${item.name}`;
      this.infoModal3.user = item;
      this.$root.$emit("bv::show::modal", this.infoModal3.id);
    },
    removeCust(user, customerId, customerName) {
      let userMetaData = user.user_metadata.customerId
        .split(",")
        .filter(custId => {
          return custId !== customerId;
        });
      user.user_metadata.customerId = userMetaData.join(",");
      ReportService.updateCustomerFromUser(user, this.$auth.accessToken)
        .then(() => {
          this.doToast("Removed Customer " + customerName + ", please wait", "success");
          setTimeout(this.getData, 2000)
        })
        .catch(err => {
          console.error(err);
          this.doToast("Failed to Remove Customer " + customerName, "danger");
        });
    },
    addCust(user, customerId, customerName) {
      let userMetaData = []
      if ("user_metadata" in user && "customerId" in user.user_metadata) {
        userMetaData = user.user_metadata.customerId.split(",");
      }
      if (!("user_metadata" in user)) {
        user["user_metadata"] = {
          customerId: "",
          roles: ""
        }
      }
      userMetaData.push(customerId);
      userMetaData = userMetaData.filter(el => el !== "");
      user.user_metadata.customerId = userMetaData.join(",");
      ReportService.updateCustomerFromUser(user, this.$auth.accessToken)
        .then(() => {
          this.$root.$emit("bv::hide::modal", this.infoModal.id);
          this.doToast("Added Customer " + customerName + ", please wait", "success");
         setTimeout(this.getData, 2000)
        })
        .catch(err => {
          console.error(err);
          this.$root.$emit("bv::hide::modal", this.infoModal.id);
          this.doToast("Failed to Add Customer " + customerName, "danger");
        });
    },
    removeRole(user, roleToRemove) {
      let userMetaData = user.user_metadata.roles.split(",").filter(role => {
        return role !== roleToRemove;
      });
      user.user_metadata.roles = userMetaData.join(",");
      ReportService.updateRolesFromUser(user, this.$auth.accessToken)
        .then(() => {
          this.doToast("Removed Role " + roleToRemove, "success");
          setTimeout(this.getData, 2000)
        })
        .catch(err => {
          console.error(err);
          this.doToast("Failed to Remove Role " + roleToRemove, "danger");
        });
    },
    addRoleToUser(user, roleToAdd) {
      let userMetaData = user.roles;
      userMetaData.push(roleToAdd);
      userMetaData = userMetaData.filter(el => el !== "");
      user.user_metadata.roles = userMetaData.join(",");
      ReportService.updateRolesFromUser(user, this.$auth.accessToken)
        .then(() => {
          this.$root.$emit("bv::hide::modal", this.infoModal2.id);
          this.doToast("Added Role " + roleToAdd, "success");
          this.getData();
        })
        .catch(err => {
          console.error(err);
          this.$root.$emit("bv::hide::modal", this.infoModal2.id);
          this.doToast("Failed to Add Role " + roleToAdd, "danger");
        });
    },
    saveUpdatedName() {
      ReportService.updateUserName(this.infoModal3.user, this.$auth.accessToken)
        .then(() => {
          this.$root.$emit("bv::hide::modal", this.infoModal3.id);
          this.doToast("Updated Name ", "success");
          this.getData();
        })
        .catch(err => {
          console.error(err);
          this.$root.$emit("bv::hide::modal", this.infoModal3.id);
          this.doToast("Failed to Update Name ", "danger");
        });
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.user = {};
      this.infoModal.customers = [];
    },
    resetInfoModal2() {
      this.infoModal2.title = "";
      this.infoModal2.user = {};
      this.infoModal2.roles = [];
    },
    resetInfoModal3() {
      this.infoModal3.title = "";
      this.infoModal3.user = {};
    },
    onFiltered(filteredItems) {
        this.totalUserCount = filteredItems.length
        this.currentPage = 1
      }
  },
  watch: {
    selectedCustomer() {
      this.getData();
    }
  }
};
</script>
